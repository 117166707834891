<template>
  <div class="page-container">
    <page-header></page-header>
    <div style="padding: 10px;">
      <el-button-group>
        <el-button type="primary" size="mini">踏勘工单</el-button>
        <el-button size="mini" @click="$router.replace('/admin/service-construction')">施工工单</el-button>
      </el-button-group>
    </div>
    <survey :p-actions="actions" :show-header="false" :order-page="getWorkOrder"></survey>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Survey from "@/views/survey/Survey";
import {getWorkOrder} from "@/api/common";
export default {
  name: "Orders",
  components: {Survey, PageHeader},
  data() {
    return{
      getWorkOrder,
      actions: [
        {
          action: 'transfer',
          label: '转单',
          type: 'primary',
          permission: 'gd:zd',
          showAction: item => {
            // 审核完成后不能转单
            return !['SUCCESS'].includes(item.state)
          }
        },
        {
          action: 'seal',
          label: '封单',
          type: 'primary',
          permission: 'gd:jf',
          showAction: item => {
            // 未封单且未完成审核
            return !['CLOSE', 'SUCCESS'].includes(item.state)
          }
        },
        {
          action: 'unblock',
          label: '解封',
          type: 'primary',
          permission: 'gd:jf',
          showAction: item => {
            return item.state === 'CLOSE'
          }
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
